/* eslint-disable */
import { BiShoppingBag, BiPencil } from "react-icons/bi";
import { BsCodeSlash, BsClipboardData } from "react-icons/bs";
import { AiOutlineMail, AiFillAudio, AiFillCode } from "react-icons/ai";
import { FaInternetExplorer, FaShoppingCart ,FaLaptopCode, FaChalkboardTeacher, FaCameraRetro, FaPinterest, FaVideo, FaTabletAlt, FaRegNewspaper } from "react-icons/fa";

 

export const servicesData = [
    {
        id: 1,
        title: 'Web Design',
        icon: <BsCodeSlash />
    },
    {
        id: 2,
        title: 'Web Development',
        icon: <FaLaptopCode /> 
    },
    {
        id: 3,
        title: 'Programming',
        icon: <AiFillCode/>
    },
    {
        id: 4,
        title: 'Digital Marketing',
        icon: <BiShoppingBag/>
    },
    // {
    //     id: 4,
    //     title: 'Internet Research',
    //     icon: <FaInternetExplorer />
    // },
    // {
    //     id: 5,
    //     title: 'Tutoring',
    //     icon: <FaChalkboardTeacher />
    // },

    // {
    //     id: 6,
    //     title: 'Web Design',
    //     icon: <BsCodeSlash />
    // },
    {
        id: 7,
        title: 'Graphic Designing',
        icon: <FaCameraRetro />
    },
    // {
    //     id: 8,
    //     title: 'Pinterest Virtual Assistant',
    //     icon: <FaPinterest />
    // }, 
    {
        id: 9,
        title: 'Online Selling',
        icon: <FaShoppingCart />
    },
    // {
    //     id: 10,
    //     title: 'Data Entry', 
    //     icon: <BsClipboardData />
    // },
    {
        id: 11,
        title: 'Internet Research',
        icon: <FaInternetExplorer />
    },
    // {
    //     id: 12,
    //     title: 'Audio Transcription',
    //     icon: <AiFillAudio />
    // },
    // {
    //     id: 13,
    //     title: 'Blog Posting',
    //     icon: <FaRegNewspaper />
    // },

]

