export const educationData = [
    {
        id: 1,
        institution: 'Coursera - Google',
        course: 'Google Career Certificates',
        startYear: ' ',
        endYear: ' '
    },
    {
        id: 2,
        institution: 'Great Learning Academy',
        course: 'Introduction To Django - Certificate Of Completion',
        startYear: '2015',
        endYear: '2019'
    },
    {
        id: 3,
        institution: 'Udemy',
        course: 'Complete Python & Python Oop With Exersizes & Projects In 2021',
        startYear: '2019',
        endYear: 'Present'
    },
    {
        id: 4,
        institution: 'BitDegree',
        course: 'Django Deployment On Digital Ocean Made Easy',
        startYear: '2019',
        endYear: 'Present'
    },
    {
        id: 5,
        institution: 'Udemy',
        course: 'SQL Bootcamp With MySql PHP & Python: 5 Course In One',
        startYear: '2019',
        endYear: 'Present'
    },
//     {
//         id: 3,
//         institution: 'Success Valley Academy',
//         course: 'Neuro Linguistic Programming (NLP) - psychology',
//         startYear: '2019',
//         endYear: 'Present'
//     },
]