import tc from '../assets/png/technokasim.png'
import st from '../assets/jpg/state-award-technokasim.jpg'
import cctv from '../assets/jpg/cctv-kasim.jpg'

export const achievementData = {
    bio : "I am proud to have achieved significant success in my career, including a Tech YouTube channel with 160k+ views, a State award for exceptional writing skills, and a certificate of participation from UCS-CCTV Product. These achievements inspire me to continue pursuing my passion for technology and striving for excellence in all aspects of my work.",
    achievements : [
       
        {
            id : 1,
            title : 'State award for writing an article',
            details : 'Awarded From Team Blue Waves, a social network literary platform created by youths from coastal region, has invited write ups on various topics from scribblers of social networking sites and blogs.',
            date : 'Dec 2014',
            field : 'Article',
            image : st },

            {
                id : 2,
                title : 'Youtube channel with 160k+ views',
                details : 'I am a tech YouTuber and passionate about technology. I use the platform to create and share content related to technology, gadgets, and software.',
                date : 'Jan 2018',
                field : 'Youtube',
                image : tc },
        {
            id : 3,
            title : 'Certificate of participation UCS-CCTV product',
            details : 'Im proud to have received a certificate of participation from UCS-CCTV Product. This recognition showcases my commitment to the field of CCTV technology and motivates me to continue exploring new advancements in the industry',
            date : 'Nov 2022',
            field : 'CCTV',
            image : cctv        }

    ]
}


// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/