import resume from '../assets/pdf/resume.pdf'
// import image from '../assets/jpg/kasim_portfolio_image.jpg'
import image from '../assets/png/kasim_portfolio_image.png'

export const headerData = {
    name: 'Mahammad Kasim',
    title: "Fullstack Web Developer",
    desciption:"An ambitious problem solver with a passion for online businesses, and who would like to join a team of like-minded developers. Kasim has much experience in creating both logical and innovative solutions to complex web problems. He is thorough and precise in everything He does and has a keen interest in technology and web applications, plus user experience. ",
    image: image,
    resumePdf: resume
}
