export const experienceData = [
    {
        id: 3,
        company: 'Sharjah - UAE',
        jobtitle: 'Fullstack Web Developer',
        startYear: 'Aug 2021',
        endYear: 'May 2023'
    },
    {
        id: 2,
        company: 'Dubai - UAE',
        jobtitle: 'Frontend Developer',
        startYear: 'Apr 2021',
        endYear: 'Aug 2021'
    },
    {
        id: 1,
        company: 'Goa - India',
        jobtitle: 'Wordpress & PHP Developer',
        startYear: 'Sep 2017',
        endYear: 'Sep 2019'
    },
]