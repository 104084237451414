import one from '../assets/svg/projects/one.svg'
import two from '../assets/svg/projects/two.svg'
import three from '../assets/svg/projects/three.svg'
import four from '../assets/svg/projects/four.svg'
import five from '../assets/svg/projects/five.svg'
import six from '../assets/svg/projects/six.svg'
import seven from '../assets/svg/projects/seven.svg'
import eight from '../assets/svg/projects/eight.svg'


export const projectsData = [
    {
        id: 1,
        projectName: 'Typing System',
        projectDesc: 'This project aims to build a typing management system that consolidates quotations, invoicing, entries, and automated reports.',
        tags: ['AWS', 'Django', 'CSS', 'Bootstrap'],
        code: 'https://wa.me/971566643080?text=Hello%20Kasim,%20Let%20me%20know%20more%20about%20typing%20system',
        demo: 'https://wa.me/971566643080?text=Hello%20Kasim,%20Let%20me%20know%20more%20about%20typing%20system',
        image: one
    },
    {
        id: 2,
        projectName: 'Bulk Messaging',
        projectDesc: 'This project aims to send bulk messages from the customer database.',
        tags: ['Php','html','CSS'],
        code: 'https://wa.me/971566643080?text=Hello%20Kasim,%20Let%20me%20know%20more%20about%20bulk%20message%20system',
        demo: 'https://wa.me/971566643080?text=Hello%20Kasim,%20Let%20me%20know%20more%20about%20bulk%20message%20system',
        image: two
    },
    {
        id: 3,
        projectName: 'Notes App',
        projectDesc: 'An Android notes app is a mobile application designed to allow users to easily create and organize notes on their Android devices.',
        tags: ['React Native', 'Material Ui'],
        code: 'https://wa.me/971566643080?text=Hello%20Kasim,%20Let%20me%20know%20more%20about%20notes%20application.',
        demo: 'https://wa.me/971566643080?text=Hello%20Kasim,%20Let%20me%20know%20more%20about%20notes%20application.',
        image: three
    },
    {
        id: 4,
        projectName: 'CCTV Website',
        projectDesc: 'A CCTV website is an online platform that provides information and resources related to closed-circuit television (CCTV) systems.',
        tags: ['React', 'Bootstrap', 'cpanel'],
        code: 'https://wa.me/971566643080?text=Hello%20Kasim,%20Let%20me%20know%20more%20about%20notes%20application.',
        demo: 'https://wa.me/971566643080?text=Hello%20Kasim,%20Let%20me%20know%20more%20about%20notes%20application.',
        image: four
    },
    {
        id: 5,
        projectName: 'E-Commerce Website',
        projectDesc: 'This is an online platform that allows businesses to sell products or services directly to customers over the internet.',
        tags: ['Django', 'css', 'Bootstrap'],
        code: 'https://wa.me/971566643080?text=Hi%20Kasim,%20Let%20me%20know%20more%20about%20ecommerce%20website.',
        demo: 'https://wa.me/971566643080?text=Hi%20Kasim,%20Let%20me%20know%20more%20about%20ecommerce%20website.',
        image: five
    },
    {
        id: 6,
        projectName: 'Typing Website',
        projectDesc: 'An online platform that provides information about a visa typing service company, including their background, services offered, and contact information.',
        tags: ['React', 'Bootstrap', 'cpanel'],
        code: 'https://wa.me/971566643080?text=Hi%20Kasim,%20Let%20me%20know%20more%20about%20typingvisa%20website.',
        demo: 'https://wa.me/971566643080?text=Hi%20Kasim,%20Let%20me%20know%20more%20about%20typingvisa%20website.',
        image: six
    },
    {
        id: 7,
        projectName: 'Courier System',
        projectDesc: 'A courier management system is a software solution designed to streamline and automate the processes involved in managing a courier or logistics company.',
        tags: ['php', 'CSS', 'Bootstrap'],
        code: 'https://wa.me/971566643080?text=Hi%20Kasim,%20Let%20me%20know%20more%20about%20courier%20system.',
        demo: 'https://wa.me/971566643080?text=Hi%20Kasim,%20Let%20me%20know%20more%20about%20courier%20system.',
        image: seven
    },
    {
        id: 8,
        projectName: 'Portfolio',
        projectDesc: 'This portfolio to showcases my work samples, achievements, skills, and experiences',
        tags: ['React', 'CSS', 'Material Ui','Firebase'],
        code: 'https://wa.me/971566643080?text=Hi%20Kasim,%20Let%20me%20know%20more%20about%20your%20portfolio.',
        demo: 'https://technokasim.com/',
        image: eight
    },
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/